<template>
  <v-autocomplete
    ref="employeeAutocomplete"
    v-model="innerValue"
    :disabled="loading"
    :loading="loading || fetching"
    :items="items"
    :search-input.sync="searchInputText"
    :clearable="clearable && !readonly"
    :cache-items="multiple || cacheItems"
    :readonly="readonly"
    :placeholder="placeholder"
    :item-value="itemValue"
    :append-icon="appendIcon"
    color="tertiary"
    :label="label"
    :item-disabled="itemDisabled"
    :multiple="multiple"
    append-icon="mdi-magnify"
    :error-messages="errorMessages"
    :return-object="returnObject"
    :filter="customFilter"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="caption" v-if="searchInputText === null && cachedItems.length === 0">{{
            $t("global.search_for_employee")
          }}</v-list-item-title>
          <v-list-item-title class="caption" v-else-if="searchInputText && searchInputText.length < 3">
            {{ $t("global.min_character_message", [3]) }}
          </v-list-item-title>
          <v-list-item-title class="caption" v-else>{{ $t("global.no_employee_message") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item, index, disabled }">
      <template v-if="!multiple">
        <span>
          {{ `${item.firstName} ${item.lastName}` }}
        </span>
      </template>
      <template v-else>
        <v-chip v-if="index < 1" :close="!readonly && !disabled" @input="innerValue.splice(index, 1)">
          {{ `${item.firstName} ${item.lastName}` }}
        </v-chip>
        <span v-if="index === 1" class="grey--text caption">
          (+{{ innerValue.length - 1 }} {{ $t("global.others") }})
        </span>
      </template>
    </template>
    <template v-slot:prepend-item v-if="multiple && cachedItems.length > 0 && !readonly && !disabled">
      <v-list-item :disabled="readonly" ripple @click="selectAllEmployees">
        <v-list-item-action>
          <v-icon :color="innerValue.length > 0 ? 'primary' : ''">{{ _icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("buttons.select_all") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>
    <template v-slot:item="{ item, attrs }">
      <v-list-item-action v-if="multiple">
        <v-checkbox hide-details :disabled="attrs.disabled" v-model="attrs.inputValue" color="primary" />
      </v-list-item-action>
      <v-list-item-avatar size="30" :color="item.profile.imageUrl ? 'white' : 'red'">
        <span v-if="!item.profile.imageUrl" class="white--text font-weight-bold">
          {{ item.firstName.substring(0, 1).toUpperCase() }}{{ item.lastName.substring(0, 1).toUpperCase() }}
        </span>
        <v-img v-else :src="item.profile.imageUrl" />
      </v-list-item-avatar>
      <v-list-item-content class="ml-2">
        <v-list-item-title class="body-1">{{ item.firstName }} {{ item.lastName }}</v-list-item-title>
        <v-list-item-subtitle class="caption" v-if="item.employment.passive">{{
          item.employment.branch.name
        }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="item.employment.passive">
        <v-list-item-action-text>{{ item.employment.quitJobDate | formattedDate }}</v-list-item-action-text>
        <v-icon size="16" color="red">mdi-circle</v-icon>
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-11 15:20:03
   * @modify date 2021-01-11 15:20:03
   * @desc EmployeeAutocomplete
   */
  import gql from "graphql-tag";
  import { ReactiveRefs } from "vue-reactive-refs";
  import Vue from "vue";

  Vue.use(ReactiveRefs);

  export default {
    name: "EmployeeAutocomplete",
    refs: ["employeeAutocomplete"],
    props: {
      value: {
        type: [String, Object, Number, Array]
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      clearable: {
        type: Boolean,
        required: false,
        default: true
      },
      readonly: {
        type: Boolean,
        required: false,
        default: false
      },
      cacheItems: {
        type: Boolean,
        required: false,
        default: false
      },
      employee: {
        type: [String, Array],
        required: false
      },
      searchInput: {
        type: [String, Number],
        required: false
      },
      placeholder: {
        type: String,
        required: false
      },
      label: {
        type: String,
        required: false,
        default() {
          return this.$t("global.employee");
        }
      },
      appendIcon: {
        type: String,
        required: false,
        default: function() {
          return this.$vuetify.icons.dropdown;
        }
      },
      errorMessages: {
        type: Array,
        required: false,
        default: () => {}
      },
      multiple: {
        type: Boolean,
        required: false,
        default: false
      },
      itemValue: {
        type: [String, Array, Function],
        required: false,
        default: "id"
      },
      itemDisabled: {
        type: [String, Array, Function],
        required: false,
        default: () => item => item.employment.passive
      },
      returnObject: {
        type: Boolean,
        required: false,
        default: false
      },
      onlyLecturer: {
        type: Boolean,
        required: false,
        default: false
      },
      passiveEmployees: {
        type: Boolean,
        required: false,
        default: false
      },
      managerType: {
        type: String,
        default: () => "HR"
      }
    },
    data: vm => ({
      searchInputText: null,
      items: [],
      criteria: {},
      GQL: {
        FILTER_EMPLOYEES: gql`
          query FilterEmployees($criteria: EmployeeFilterCriteria!) {
            filterEmployees(criteria: $criteria) {
              id
              firstName
              lastName
              phone
              profile {
                imageUrl
              }
              employment {
                quitJobDate
                passive
                jobInfo {
                  name
                }
                branch {
                  name
                }
              }
            }
          }
        `
      },
      fetching: false,
      innerEmployee: null
    }),
    computed: {
      innerValue: {
        get() {
          return this.multiple ? this.value || [] : this.value;
        },
        set(val) {
          this.$emit("input", val);
          this.searchInputText = null;
        }
      },
      isAllSelectedEmployees() {
        if (this.multiple && Array.isArray(this.innerValue)) {
          return this.innerValue.length === this.cachedItems.length;
        }
        return false;
      },
      isLikesSelectedEmployees() {
        if (this.multiple && Array.isArray(this.innerValue))
          return this.innerValue.length > 0 && !this.isAllSelectedEmployees;
        return false;
      },
      _icon() {
        if (this.isAllSelectedEmployees) return "mdi-close-box";
        if (this.isLikesSelectedEmployees) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      },
      cachedItems() {
        return this.$helpers.get(this.$refs, "employeeAutocomplete.cachedItems", []);
      }
    },
    watch: {
      employee: {
        handler(id) {
          if (!this.innerEmployee || (Array.isArray(id) && id.length !== this.innerEmployee.length)) {
            this.innerEmployee = id;
          }
        },
        deep: true,
        immediate: true
      },
      innerEmployee: {
        deep: true,
        immediate: true,
        handler(id) {
          if (id) {
            if (Array.isArray(id)) {
              if (id.length > 0) {
                this.criteria.ids = id;
              }
            } else {
              this.criteria.id = id;
            }
          } else {
            delete this.criteria.id;
            delete this.criteria.ids;
          }
          this.filterEmployees();
        }
      },
      searchInputText(val) {
        delete this.criteria.id;
        delete this.criteria.ids;
        if (val) {
          this.criteria.fullName = val;
        } else {
          delete this.criteria.fullName;
        }
        if (val && val.length >= 3) {
          this.filterEmployees();
          this.$emit("update:search-input", val);
        }
      },
      onlyLecturer: {
        immediate: true,
        handler(onlyLecturer) {
          if (this.onlyLecturer) {
            this.criteria.lecturer = true;
            this.filterEmployees();
          } else {
            delete this.criteria.lecturer;
          }
        }
      },
      passiveEmployees: {
        immediate: true,
        handler(passiveEmployees) {
          if (this.passiveEmployees) {
            this.criteria.passive = true;
            this.filterEmployees();
          } else {
            delete this.criteria.passive;
          }
        }
      }
    },
    methods: {
      selectAllEmployees() {
        this.$nextTick(() => {
          if (this.isAllSelectedEmployees) {
            this.innerValue = [];
          } else {
            if (this.returnObject) {
              this.innerValue = this.cachedItems;
            } else {
              this.innerValue = this.cachedItems.map(item => item.id);
            }
          }
        });
      },
      customFilter(item, queryText, itemText) {
        const firstName = item.firstName.toLocaleLowerCase(navigator.language);
        const lastName = item.lastName.toLocaleLowerCase(navigator.language);
        const searchText = queryText.toLocaleLowerCase(navigator.language);
        return firstName.indexOf(searchText) > -1 || lastName.indexOf(searchText) > -1;
      },
      filterEmployees() {
        if (Object.keys(this.criteria).length) {
          this.fetching = true;
          this.$apollo
            .query({
              query: this.GQL.FILTER_EMPLOYEES,
              variables: {
                criteria: this.criteria
              },
              fetchPolicy: "no-cache"
            })
            .then(({ data }) => {
              this.items = (data && data.filterEmployees) || [];
            })
            .catch(error => {
              console.error(error);
            })
            .finally(() => (this.fetching = false));
        }
      }
    }
  };
</script>

<style lang="scss">
  .item-tile-content {
    margin-left: -16px;
    margin-right: -16px;
  }
</style>
