var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{ref:"employeeAutocomplete",attrs:{"disabled":_vm.loading,"loading":_vm.loading || _vm.fetching,"items":_vm.items,"search-input":_vm.searchInputText,"clearable":_vm.clearable && !_vm.readonly,"cache-items":_vm.multiple || _vm.cacheItems,"readonly":_vm.readonly,"placeholder":_vm.placeholder,"item-value":_vm.itemValue,"append-icon":_vm.appendIcon,"color":"tertiary","label":_vm.label,"item-disabled":_vm.itemDisabled,"multiple":_vm.multiple,"append-icon":"mdi-magnify","error-messages":_vm.errorMessages,"return-object":_vm.returnObject,"filter":_vm.customFilter},on:{"update:searchInput":function($event){_vm.searchInputText=$event},"update:search-input":function($event){_vm.searchInputText=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[(_vm.searchInputText === null && _vm.cachedItems.length === 0)?_c('v-list-item-title',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("global.search_for_employee")))]):(_vm.searchInputText && _vm.searchInputText.length < 3)?_c('v-list-item-title',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("global.min_character_message", [3]))+" ")]):_c('v-list-item-title',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("global.no_employee_message")))])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
var disabled = ref.disabled;
return [(!_vm.multiple)?[_c('span',[_vm._v(" "+_vm._s(((item.firstName) + " " + (item.lastName)))+" ")])]:[(index < 1)?_c('v-chip',{attrs:{"close":!_vm.readonly && !disabled},on:{"input":function($event){return _vm.innerValue.splice(index, 1)}}},[_vm._v(" "+_vm._s(((item.firstName) + " " + (item.lastName)))+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.innerValue.length - 1)+" "+_vm._s(_vm.$t("global.others"))+") ")]):_vm._e()]]}},(_vm.multiple && _vm.cachedItems.length > 0 && !_vm.readonly && !_vm.disabled)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"disabled":_vm.readonly,"ripple":""},on:{"click":_vm.selectAllEmployees}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.innerValue.length > 0 ? 'primary' : ''}},[_vm._v(_vm._s(_vm._icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("buttons.select_all")))])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null,{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [(_vm.multiple)?_c('v-list-item-action',[_c('v-checkbox',{attrs:{"hide-details":"","disabled":attrs.disabled,"color":"primary"},model:{value:(attrs.inputValue),callback:function ($$v) {_vm.$set(attrs, "inputValue", $$v)},expression:"attrs.inputValue"}})],1):_vm._e(),_c('v-list-item-avatar',{attrs:{"size":"30","color":item.profile.imageUrl ? 'white' : 'red'}},[(!item.profile.imageUrl)?_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(item.firstName.substring(0, 1).toUpperCase())+_vm._s(item.lastName.substring(0, 1).toUpperCase())+" ")]):_c('v-img',{attrs:{"src":item.profile.imageUrl}})],1),_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{staticClass:"body-1"},[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))]),(item.employment.passive)?_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(item.employment.branch.name))]):_vm._e()],1),(item.employment.passive)?_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v(_vm._s(_vm._f("formattedDate")(item.employment.quitJobDate)))]),_c('v-icon',{attrs:{"size":"16","color":"red"}},[_vm._v("mdi-circle")])],1):_vm._e()]}}],null,true),model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }